<template>
  <div class="city_service">
    <VTextField
      v-if="!disabledInput"
      v-model="localAmount"
      autofocus
      class="form-field type-text"
      color="success"
      dense
      outlined
      placeholder="Например: Событие"
    />
    <span v-else class="city_service--text">
      {{ localAmount }} <span>₸</span>
    </span>
    <VBtn
      class="my-auto"
      icon
      @click="toggleInput"
    >
      <VIcon v-if="disabledInput" color="#D0021B">
        mdi-pencil
      </VIcon>
      <VIcon v-else color="#4caf50">
        mdi-check
      </VIcon>
    </VBtn>
  </div>
</template>

<script>
export default {
  name: 'CityServiceFeeEdit',
  props: {
    amount: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ['changeServiceFee'],
  data() {
    return {
      disabledInput: true,
      localAmount: this.amount,
    };
  },
  mounted() {
    this.localAmount = this.amount;
  },
  methods: {
    toggleInput() {
      this.disabledInput = !this.disabledInput;
      if (this.disabledInput) {
        this.$emit('changeServiceFee', this.localAmount);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.city_service {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  min-width: 100px;

  &--text {
    display: flex;
    align-items: center;
    gap: 3px;
  }
}
</style>
