<template>
  <VRow class="mx-7 mt-3 d-flex flex-wrap">
    <VDialog v-model="dialog" max-width="50%">
      <RestaurantScheduleList
        :value="selectedSchedule"
        @input="changeSchedule"
      />
    </VDialog>
    <VCol cols="12">
      <span class="page_title">
        Управление городами
      </span>
    </VCol>
    <VCol v-if="cities.length > 0" cols="12">
      <VSimpleTable class="elevation-2">
        <thead>
          <tr class="titles-thead">
            <th class="text-left">
              <span>Город</span>
            </th>
            <th class="text-right">
              <span>Состояние</span>
            </th>
            <th class="text-center">
              <span>Сужение</span>
            </th>
            <th class="text-center">
              <span>Повышенный тариф</span>
            </th>
            <th>
              <span>Текущий тариф</span>
            </th>
            <th>
              <span>Service fee</span>
            </th>
            <th class="service-fee-vd">
              <span>Service fee для VD</span>
            </th>
            <th class="px-0">
              <span>График</span>
            </th>
            <th class="text-left">
              <span>Оплата</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(city,index) in cities" :key="city.pk">
            <td
              class="left-row text-left"
              :class="!city.ownDeliveryAvailable ? 'red--text' : ''"
            >
              {{ city.title }}
            </td>
            <td class="pr-5">
              <VSwitch
                class="mx-auto mr-0"
                flat
                :input-value="city.ownDeliveryAvailable"
                inset
                @change="changeCityState(city.pk, index, 'ownDeliveryAvailable', $event)"
              />
            </td>
            <td class="pl-5">
              <VSwitch
                class="mx-auto"
                flat
                :input-value="city.isReducedAreaActive"
                inset
                @change="changeCityState(city.pk, index, 'isReducedAreaActive', $event)"
              />
            </td>
            <td class="pl-5">
              <VSwitch
                :key="tariffKey"
                class="mx-auto"
                flat
                :input-value="!!city.hotTariff"
                inset
                @change="changeCityTariff(city, $event)"
              />
            </td>
            <td class="pl-5">
              <div class="d-flex">
                <p class="my-auto mr-1 overflow-hidden tariff-field-width">
                  {{ city.hotTariff ? city.hotTariff.title : '-' }}
                </p>
                <VBtn
                  v-show="city.hotTariff"
                  class="my-auto"
                  icon
                  @click="openTariffDialog(city)"
                >
                  <VIcon color="#D0021B">
                    mdi-pencil
                  </VIcon>
                </VBtn>
              </div>
            </td>
            <td class="pl-5 flex">
              <CityServiceFeeEdit
                :amount="city.service_fee.amount"
                @changeServiceFee="changeCityState(city.pk, index, 'service_fee',
                                                   {...city.service_fee, amount: $event})"
              />
            </td>
            <td class="pl-5">
              <VSwitch
                v-if="city.service_fee"
                class="mx-auto"
                flat
                :input-value="city.service_fee.is_enabled_for_vd"
                inset
                @change="changeCityState(
                  city.pk, index, 'service_fee', {...city.service_fee, is_enabled_for_vd: $event},
                )"
              />
            </td>
            <td class="pa-0">
              <VBtn
                class="default-btn elevation-0"
                color="#4caf50"
                dark
                rounded
                @click="selectSchedule(city, index)"
              >
                Расписание
              </VBtn>
            </td>
            <td class="pl-5">
              <PaymentTypesSelection
                :choices="paymentChoices"
                disable-description
                disable-request
                :value="city.paymentMethods"
                @input="changePaymentMethods(index, $event)"
              />
            </td>
          </tr>
        </tbody>
      </VSimpleTable>
      <VDialog
        v-model="tariffDialog"
        content-class="br-12"
        hide-overlay
        width="759"
      >
        <Tariff
          :key="key"
          :city-pk="selectedCityPk"
          :tariff-id="selectedTariffId"
          @close="tariffDialog = false"
          @update="updateCities"
        />
      </VDialog>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RestaurantScheduleList from '@/components/RestaurantScheduleList.vue';
import PaymentTypesSelection from '@/components/PaymentTypesSelection.vue';
import Tariff from '@/components/HotTariff.vue';
import { patchCity, fetchRestaurantChoices } from '@/api/api';
import CityServiceFeeEdit from '@/components/CityServiceFeeEdit.vue';

export default {
  components: {
    CityServiceFeeEdit,
    Tariff,
    RestaurantScheduleList,
    PaymentTypesSelection,
  },
  data() {
    return {
      dialog: false,
      tariffDialog: false,
      selectedTariffId: null,
      selectedSchedule: null,
      selectedCity: {},
      selectedCityIndex: null,
      selectedCityPk: null,
      paymentChoices: [],
      temporarySchedule: null,
      key: 0,
      tariffKey: 0,
    };
  },
  beforeMount() {
    this.getCities();
    fetchRestaurantChoices().then((choices) => {
      this.paymentChoices = choices.paymentChoices;
    });
  },
  computed: {
    ...mapGetters(['cities']),
  },
  methods: {
    ...mapActions(['getCities']),
    changeCityState(cityPk, index, key, value) {
      this.cities[index][key] = value;
      const deserializedKeys = {
        ownDeliveryAvailable: 'own_delivery_available',
        isReducedAreaActive: 'is_reduced_area_active',
        schedule: 'schedule',
        paymentMethods: 'payment_methods',
        is_enabled_for_vd: 'is_enabled_for_vd',
        service_fee: 'service_fee',
      };
      const body = {
        [deserializedKeys[key]]: this.cities[index][key],
      };
      patchCity(cityPk, body).catch(() => {
        if (typeof this.cities[index][key] === 'boolean') {
          this.cities[index][key] = !value;
        }
      });
    },
    selectSchedule(city, index) {
      this.selectedCity = city;
      this.selectedCityIndex = index;
      this.selectedSchedule = city.schedule;
      this.dialog = true;
    },
    changeSchedule(schedule) {
      this.temporarySchedule = schedule;
      setTimeout(() => {
        if (this.temporarySchedule === schedule) {
          const deserializedSchedule = schedule.map((day) => day.deserialized);
          this.changeCityState(
            this.selectedCity.pk,
            this.selectedCityIndex,
            'schedule',
            deserializedSchedule,
          );
        }
      }, 1000);
    },
    changePaymentMethods(index, paymentMethods) {
      this.cities[index].paymentMethods = paymentMethods;
      this.changeCityState(this.cities[index].pk, index, 'paymentMethods', paymentMethods);
    },
    changeCityTariff(city, value) {
      if (value) {
        this.openTariffDialog(city);
      } else {
        patchCity(city.pk, { hot_tariff_id: -1 }).then(() => {
          this.getCities();
        });
      }
    },
    updateCities() {
      this.tariffDialog = false;
      this.getCities();
    },
    openTariffDialog(city) {
      this.key += 1;
      this.selectedCityPk = city.pk;
      this.selectedTariffId = city.hotTariff ? city.hotTariff.id : null;
      this.tariffDialog = true;
    },
  },
  watch: {
    tariffDialog(val) {
      if (!val) {
        this.tariffKey += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.titles-thead th {
  color: $default_dark_gray_color !important;
}
tbody tr{
  height: 60px;
  td{
    height: 100%;
    .v-input {
      width: 60px;
    }
  }
}
.service-fee-vd {
  min-width: 135px;
}
::v-deep{
  .v-input--switch__thumb {
    background-color: $hover_red_color;
  }
  .v-input--switch__track {
    background-color: $transparent_red_color;
  }
  .v-input--is-label-active {
    .v-input--switch__thumb {
      background-color: $hover_green_color !important;
    }
    .v-input--switch__track {
      background-color: $transparent_green_color !important;
    }
  }
}
</style>
