import { render, staticRenderFns } from "./CityServiceFeeEdit.vue?vue&type=template&id=23f9ef9d&scoped=true"
import script from "./CityServiceFeeEdit.vue?vue&type=script&lang=js"
export * from "./CityServiceFeeEdit.vue?vue&type=script&lang=js"
import style0 from "./CityServiceFeeEdit.vue?vue&type=style&index=0&id=23f9ef9d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f9ef9d",
  null
  
)

export default component.exports